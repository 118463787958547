:root {
    --themeFont: 'Raleway';
    --themeFontThin: 'Raleway Thin';
    --themeFontLight: 'Raleway Light';
    --themeFontRegular: 'Raleway Regular';
    --themeFontBold: 'Raleway Bold';

    --smallIconSize: 22px;
    --themeColor3: #333333;
    --themeColor6: #666666;
    --themeColor9: #999999;
    --themeBlueColor: #0033ff;
    --themeWhiteColor: #ffffff;
    --themeGreenColor: #c4ab00;
    --themeDisabledColor: rgba(0, 0, 0, 0.38);

    --cursorDefault: default;
}


html {
    font-face: 'Raleway';
    line-height: 18px;
    height: 100%;
    font-size: 14px;
    color: #000;
    overflow-y: hidden;
}

body {
    /* overflow: hidden; */
    max-width: 100% !important;
    font-family: 'Raleway';
    font-style: normal;
    /* transition: all 0.5s; dont use this otherwise dialog box with create problem*/
    background-color: #ffffff;
    cursor: text;
}

img {
    max-width: 100%;
}

button {
    border: none;
    background: none;
}

::placeholder {
    opacity: 0.7;
    color: #bbb;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.marL70R0 {
    margin-left: 70px;
}

.wordBreak {
    word-break: break-word;
}

.clNoWrap {
    white-space: nowrap;
}

.clJustifyContentEvenly {
    justify-content: space-evenly;
}

.clPad15 {
    padding: 15px;
}

.clMainFESuggImgs {
    max-height: 500px;
    overflow-y: auto;
}

.ck-editor__editable {
    min-height: 250px;
    max-height: 250px;
    overflow-y: auto !important;
}

.clFESingleSug+.MuiCollapse-container {
    max-height: 350px !important;
    overflow-y: auto !important;
}

/*Initial loading start*/
/* .MuiBackdrop-root{
 display: flex !important;
 } */
.clLoadingPer {
    font-size: 100px;
    color: #d0d0d0;
    position: relative;
    top: -50px;
    font-family: 'Raleway-Thin';
}

.clLoadingFloTxt {
    color: #747774;
    font-size: 24px;
    font-family: 'Raleway-Thin';
    position: relative;
    top: 40px;
}

/*Initial loading over*/
/*Overall FB CK Editor start*/
.ckeValidLabel {
    text-align: right;
    width: 100%;
    font-size: 0.75rem;
    color: #f44336;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
}

/*Overall FB CK Editor over*/
/*Menu starts*/
.clUserMentor #liFloLogo {
    cursor: pointer;
}

#divMainIntMenu {
    min-height: 600px;
}

.clMenu {
    background: #c4ab00;
}

.clMenu ul {
    list-style: none;
    padding-left: 0;
    cursor: default;
    margin: 0;
    /* due to Mui */
}

.clMenu ul li.active {
    background-color: #fff;
}

.clMenu ul li:first-child {
    margin-bottom: 38px;
    padding-top: 18px;
}

.clMenu ul li {
    padding: 14px 0;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    margin: 0px;
}

.clMenu ul li img {
    /*width: 36px;*/
    height: 36px;
}

.clMenu ul li:first-child img {
    width: 30px;
    height: 30px;
}

.clIconBlackColor {
    filter: invert(0%) sepia(0%) saturate(7%) hue-rotate(322deg) brightness(103%) contrast(101%);
}

/* .clIconWhiteColor
 {
 filter: invert(100%) sepia(0%) saturate(7433%) hue-rotate(47deg) brightness(120%) contrast(114%);
 } */
.clIconWhiteColor {
    filter: invert(100%) sepia(0%) saturate(7433%) hue-rotate(192deg) brightness(120%) contrast(114%);
}

.clIconGoldenColor {
    filter: invert(83%) sepia(43%) saturate(5108%) hue-rotate(12deg) brightness(91%) contrast(101%);
}

.clIconBlueColor {
    filter: invert(12%) sepia(97%) saturate(5733%) hue-rotate(233deg) brightness(97%) contrast(118%);
}

.padR0 {
    padding-right: 0;
}

.clMainItemIntPg {
    display: none;
}

/* #liFESugg, #liChat{
 display: none;
 } */
/*Menu over*/
.MuiInputLabel-outlined {
    transform: translate(12px, 12px) scale(1);
}

#divMainTabItems {
    padding-left: 5px;
    padding-right: 12px;
}

.clCanInfo {
    line-height: 1.1;
    padding-top: 18px;
}

.statusComm {
    font-family: 'Raleway Bold', 'Raleway Regular', 'Raleway';
    font-weight: 700;
    font-size: 9px;
    text-transform: uppercase;
    padding: 1px 3px 0;
    margin-left: 15px;
    position: relative;
    top: -3px;
    font-style: normal;
}

.statusOnline {
    background-color: #090;
    color: #fff;
}

.statusOffline {
    background-color: #F00;
    color: #ff0;
    text-transform: uppercase;
}

.Raleway12 {
    font-family: 'Raleway', 'Raleway Regular';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #666666;
}

.clSuggBGColor {
    background-color: #ffc;
}

.clTabHed {
    font-family: 'Raleway', 'Raleway Regular', 'Raleway';
    font-weight: 300;
    font-style: normal;
    color: #009900;
    text-transform: uppercase;
    font-size: 28px;
    text-align: left;
    line-height: 36px;
    text-rendering: optimizeLegibility;
    font-feature-settings: "kern" 1;
    -webkit-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern=1";
    font-kerning: normal;
    padding-left: 12px;
    margin-bottom: 26px;
}

.clTabData {
    overflow-x: hidden;
    /* max-height: 400px; */
    /*padding-top: 6px*/
}

.MuiFormHelperText-contained {
    margin-right: 0 !important;
}

/*Tab live interview start*/
#divMainVideoIntLarge {
    transition: all 0.3s;
    display: inline-block;
}

#divMainVideoIntLarge img,
#divMainVideoIntSmall img {
    max-width: 98%;
}

#divSubVideoIntSmall {
    position: absolute;
    padding-top: 5px;
    /* right: 20px; */
    left: 33%;
}

#divMainVideoContainer {
    transition: all 0.3s;
    position: relative;
}

#divMainVideoIntSmallInside {
    position: absolute;
    bottom: 0px;
    background-color: #333333;
}

#divMainVideoIntSmall #divSubVideoIntSmall #divMainVideoIntSmallInside {
    bottom: 0px;
    left: 0px;
}

#divMainVideoIntSmallInside img {
    max-width: 100%;
}

.mainCntTxt,
.smCntTxt {
    color: #ffffff;
    height: 100%;
    /* display: flex;
     justify-content: center; */
    display: grid;
    grid-template-rows: 50px auto;
    overflow-y: auto;
    overflow-x: hidden;
}

.mainCntTxtCan,
.smCntTxt {
    color: #ffffff;
    height: 100%;
    /* display: flex;
     justify-content: center; */
    display: grid;
    grid-template-rows: auto;
}

.mainCntTxt div,
.smCntTxt div {
    align-self: center;
}

#divMainVideoIntSmall .smCntTxt {
    /* padding-top: 1em; */
}

.clCESub {
    display: block;
    padding-top: 15px;
}

/*Tab live interview over*/
.clRaleway8 {
    font-family: 'Raleway';
    font-weight: 400;
    color: #AEAEAE;
    text-rendering: optimizeLegibility;
    font-feature-settings: "kern" 1;
    -webkit-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern=1";
    font-kerning: normal;
    font-size: 8px;
    line-height: 11px;
    text-transform: uppercase;
    padding-right: 3px;
}

.clLineHeigh05 {
    line-height: 0;
}

.padL60 {
    padding-left: 60px;
}

.padL115 {
    padding-left: 115px;
}

.padLR16 {
    padding-left: 16px;
    padding-right: 16px;
}

#divMainBotBar {
    /* position: relative;
     left: -15px; */
    /* z-index: 10000; */
    margin-left: 60px;
}

#divSubBotBar {
    position: fixed;
    bottom: 0;
    width: 96%;
    border-top: solid 1px #d7d7d7;
    background-color: #fff;
    z-index: 100;
}

.clBotBarSec {
    border-right: solid 1px #d7d7d7;
}

.clRaleway10 {
    font-family: 'Raleway';
    font-weight: 400;
    color: #999999;
    text-rendering: optimizeLegibility;
    font-feature-settings: "kern" 1;
    -webkit-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern=1";
    font-kerning: normal;
    font-size: 11px;
    line-height: 24px;
}

.clColorGreen {
    color: #009900;
}

.clColorRed {
    color: #F00;
}

.padL10 {
    padding-left: 10px;
}

.padR10 {
    padding-right: 10px;
}

.marT36 {
    margin-top: 36px;
}

#iframeResume {
    max-width: 100%;
    width: 100%;
    height: 400px;
    max-height: 400px;
    overflow: scroll;
}

.clTabDataInside {
    padding-left: 32px;
}

.clIntBreifing {
    margin-top: 18px;
}

.clRaleway14 {
    font-family: 'Raleway';
    font-weight: 400;
    text-rendering: optimizeLegibility;
    font-feature-settings: "kern" 1;
    -webkit-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern=1";
    font-kerning: normal;
    font-style: normal;
    font-size: 14px;
    color: #333333;
    text-align: left;
    line-height: 18px;
}

.clRalewayBold {
    margin: 0px;
    text-rendering: optimizeLegibility;
    font-feature-settings: "kern" 1;
    -webkit-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern=1";
    font-kerning: normal;
    font-family: 'Raleway Bold', 'Raleway Regular', 'Raleway';
    font-weight: 700 !important;
    /*color:#333333;*/
}

.clColor3 {
    color: #111111;
}

.clColor6 {
    color: #666666;
}

.clFS18 {
    font-size: 18px;
    line-height: 24px;
}

.clFS14 {
    font-size: 14px;
    line-height: 18px;
}

#divMainJD {
    margin-top: 15px;
    font-size: 1rem;
    padding-right: 12px;
}

#divJDTitle {
    padding-bottom: 24px;
}

.divCanHisTopBor {
    width: 96%;
    border-top: solid 1px #333333;
    padding: 4px 0;
}

.clCanHisPad10 {
    padding: 4px 0;
}

table.clHistoryTable tr {
    font-family: 'Raleway';
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
}

table.clHistoryTable tr:first-child {
    text-transform: uppercase;
    font-family: 'Raleway Bold', 'Raleway Regular', 'Raleway';
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    color: #999999;
    border-top: solid 2px;
    border-bottom: solid 2px;
}

#divJDData h4 {
    font-size: 1rem;
    font-weight: 700;
}

.img32 {
    width: 32px;
    max-width: 32px;
}

.img24 {
    width: 24px;
    max-width: 24px;
}

.Raleway10 {
    font-size: 10px;
    color: 0;
}

.clMainMediaControl div * {
    pointer-events: none;
    white-space: nowrap;
}

.clMediaControl {
    cursor: default;
    display: inline-block;
}

.padR80 {
    /*padding-right: 80px;*/
}

.marTB5 {
    margin-top: 5px;
    margin-bottom: 10px;
}

.btnDisabled {
    border-radius: 50px !important;
    color: rgba(0, 0, 0, 0.38) !important;
    border: 1px solid rgba(0, 0, 0, 0.38) !important;
    padding: 5px 20px !important;
    margin: 0 6px !important;
    /*box-shadow: 0px 5px 10px #ccc !important;*/
}

.btnOutline {
    border-radius: 50px !important;
    color: #0033ff !important;
    border: 1px solid #0033ff !important;
    padding: 5px 20px !important;
    margin: 0 6px !important;
    /*box-shadow: 0px 5px 10px #ccc !important;*/
}

.btnContained {
    background-color: #0033ff !important;
    color: #ffffff !important;
    border: none !important;
    padding: 5px 60px !important;
    font-weight: 700 !important;
    /* 5px 20px !important; */
    /* 400 !important; */
    font-size: 18px !important;
    /* 14px !important; */
    border-radius: 50px !important;
    margin: 0 6px !important;
    text-transform: none !important;
}

.btnBorder {
    background-color: #ffffff !important;
    color: #0033ff !important;
    border: solid 1px #0033ff !important;
    padding: 5px 20px !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    border-radius: 50px !important;
    margin: 0 6px !important;
    text-transform: inherit !important;
    font-style: normal !important;
    box-shadow: none !important;
}

.btnContainedPad80 {
    padding: 5px 100px !important;
}

.clRightPartGray {
    background-color: #f5f5ef;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    padding-right: 0px;
    overflow: hidden;
    padding-left: 20px;
}

.clRightPartGrayInner {
    margin: 20px 0;
    min-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    padding-right: 20px;
    padding-bottom: 150px;
    max-width: 97%;
}

.clDDRound {
    border: solid 1px #0033ff !important;
    color: #0033ff;
    background-color: #fff !important;
    overflow: hidden;
    border-radius: 40px;
}

/* .MuiInput-underline:before{
 border-bottom: 0 !important;
 } */
.MuiSelect-select.MuiSelect-select {
    color: #00f;
}

.MuiButton-root {
    text-transform: none;
}

.clBlueFont {
    color: #0033ff;
}

.clTabPanel {
    padding: 8px 0 8px 10px;
}

.clSmallIconSize {
    width: 22px;
    height: 22px;
    position: relative;
    top: 1px;
}

.clMainBookmarkVideo {
    color: #0033ff;
    font-size: 12px;
    cursor: default;
    display: inline-block;
}

.clBookmarkTime {
    color: #999999;
    margin-left: 12px;
}

button:focus {
    outline: none !important;
}

/*FE Suggestion*/
.MuiOutlinedInput-root {
    border-radius: 0 !important;
}

.clMainSingleFESug,
.clMainSkillRate {
    background: #ffffff;
    border-radius: 10px;
    padding-right: 25px;
    margin-bottom: 25px;
    overflow-x: hidden;
}

.clSeqGreen {
    font-family: 'Raleway';
    font-weight: 400;
    font-style: normal;
    font-size: 32px;
    color: #0c0;
    line-height: 1;
    margin-top: 4px;
}

.clTA100 {
    width: 100%;
}

.padT8 {
    padding-top: 8px;
}

.padT20 {
    padding-top: 20px;
}

.clYrRating {
    font-size: 10px;
    color: #000000;
    padding-right: 10px;
    position: relative;
    top: 3px;
}

.clInlineBlock {
    display: inline-block;
}

.clDispFlex {
    display: flex;
}

.clDDEditorList {
    padding: 2px;
    min-width: 130px;
}

.clFloSwith {
    margin-right: 5px;
}

.clFloSwithTxt {
    color: #0033ff;
    letter-spacing: 0.5px;
}

.clTAEditor {
    width: 100%;
    height: 250px;
    background-color: #333333;
    min-width: 100%;
    max-width: 100%;
}

.clMarT20R10 {
    margin-top: 20px;
}

.clTxtLeft {
    text-align: left;
}

.clMarT110 {
    margin-top: 250px;
}

.clEOQ {
    background-color: #c9c9c9;
    text-align: center;
    color: #ffffff;
    padding: 6px;
    text-transform: uppercase;
}

.clMainSkillRate {
    margin-top: 20px;
}

.clSkillRate {
    padding: 18px 30px;
}

.clRateCanSkillBold {
    font-family: 'Raleway Bold', 'Raleway Regular', 'Raleway';
    font-weight: 700;
    font-style: normal;
    font-size: 13px;
    letter-spacing: 0.3px;
    margin-bottom: 10px;
}

.clSingleSkill {
    margin-top: 12px;
    margin-bottom: 12px;
}

.clMainCanPerfom {
    margin-top: 35px;
}

.clTAOverAllFB {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 100px;
}

.clMainCanOverAllRate {
    margin-top: 5px;
}

.clRaleway10-3 {
    font-size: 10px;
    color: #333333;
    margin-right: 15px;
}

.padL5 {
    padding-left: 5px;
}

.padR15 {
    padding-right: 15px;
}

.clMainCanVerdict {
    margin-top: 18px;
}

.clMainCanVerdictOpts label {
    font-size: 12.5px;
    color: #333333;
    margin-left: 5px;
}

.MuiInput-underline.Mui-focused:after {
    transform: scaleX(0) !important;
}

.clMenu ul li *,
.clMenuItem *,
.clFESingleSug * {
    pointer-events: none;
}

.clFESingleSug {
    cursor: default;
}

.clFESingleSugDet {
    color: #999999;
    padding-left: 20px;
}

.clFESuggDashBorder {
    border-left: dashed 1px #ccc;
    padding-bottom: 5px;
}

.clSuggBGColor {
    background-color: #ffc;
}

#smallBtnBar div:not(.MuiBackdrop-root) {
    display: inline-block;
}

#resFinishBtn {
    cursor: default;
}

#resFinishBtn button {
    border-radius: 50%;
    padding: 6px;
}

#mediaAudoCall {
    display: none;
    border-radius: 50%;
    padding: 5px;
    overflow: hidden;
}

#divMainAudioCallOnOff {
    border-radius: 50%;
    padding: 5px;
}

/*code mirror starts*/
.cm-s-material.CodeMirror,
.CodeMirror {
    background-color: #333333 !important;
}

.CodeMirror-gutter.CodeMirror-linenumbers {
    background-color: #000000;
}

.cm-s-material .CodeMirror-guttermarker,
.cm-s-material .CodeMirror-guttermarker-subtle,
.cm-s-material .CodeMirror-linenumber {
    font-family: 'Courier New' !important;
    font-weight: 400;
    font-style: normal;
    color: #FFFFFF !important;
}

/* .react-codemirror2 {
 max-height: 250px;
 } */
/*code mirror over*/
/*new buttns on candidate detail part start*/
#mediaAudoCall {
    margin-left: 30px;
}

/*new buttns on candidate detail part over*/
/*changes for candidate login starts*/
.clMenuShadow {
    width: 100%;
    height: 10px;
    margin: 18px 0;
}

#divCanFinishInt {
    padding-right: 20px;
}

.clCanSideSmallVideo {
    position: fixed !important;
    top: 0;
    max-width: 25%;
    left: 37%;
}

#ddIntIssueReport .MuiPaper-rounded {
    border-radius: 20px !important;
}

.dialogSubTitle {
    font-family: 'Raleway';
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    padding-top: 5px;
    text-align: left;
    line-height: 18px;
    color: #333333;
}

.clMainFBDialogHed4 {
    background-color: #f2f2f2;
    padding-top: 10px;
    border-bottom: solid 2px #0c0;
}

.clFBDialogSingleHed {
    border-left: dashed 1px #ccc;
    margin-left: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    /* height: 100%; */
}

.clFBDialogTitle4 {
    text-transform: uppercase;
    font-size: 14px;
    color: #666666;
}

.clFBDialogData4 {
    font-size: 16px;
    color: #000000;
}

#divMainCanFBQue {
    margin-top: 30px;
    margin-left: 15px;
}

.clDivRdlFB {
    display: inline-block;
    margin-right: 30px;
    white-space: nowrap;
}

.clDivRdlFB input {
    vertical-align: top;
}

.clDivRdlFB label {
    position: relative;
    top: -2px;
    left: 2px;
    white-space: normal;
}

.taCanFB {
    width: 70%;
    height: 100px;
}

.marB10 {
    margin-bottom: 15px;
}

.marB30 {
    margin-bottom: 30px;
}

/*changes for candidate login over*/
.clMainRightPart .MuiInputBase-root {
    margin: 0 4px !important;
}

/*Add question start*/
.clDialogCloseBtn {
    color: #0033ff !important;
}

.clAddQue {
    display: flex;
    cursor: pointer;
}

.clArial13 {
    font-family: 'Raleway';
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    text-align: left;
    line-height: 18px;
    color: #0033ff;
    margin-left: 5px;
}

.clTAComm {
    border-color: -internal-light-dark-color(#767676, #c3c3c3);
}

.marT-24 {
    position: relative;
    margin-top: -24px !important;
}

.clDividerBorderTop {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: none !important;
}

.clDividerBorderBottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none !important;
}

.clDialogFooter {
    padding-right: 20px !important;
    position: relative;
    bottom: 20px;
}

/*Add question over*/
/*Responsive design start*/
@media only screen and (min-width: 992px) {
    .col-lg-0-5 {
        max-width: 4.166666667%;
        flex: 0 0 4.166666667%;
    }

    .col-lg-0-6 {
        max-width: 5%;
        flex: 0 0 5%;
    }

    .col-lg-1-15 {
        max-width: 9.583333333%;
        flex: 0 0 9.583333333%;
    }

    .col-lg-1-25 {
        max-width: 10.41666667%;
        flex: 0 0 10.41666667%;
    }

    .col-lg-1-5 {
        max-width: 12.5%;
        flex: 0 0 12.5%;
    }

    .col-lg-2-5 {
        max-width: 20.83333333%;
        flex: 0 0 20.83333333%;
    }

    .col-lg-3-5 {
        max-width: 29.16666667%;
        flex: 0 0 29.16666667%;
    }

    .col-lg-4-5 {
        max-width: 37.5%;
        flex: 0 0 37.5%;
    }

    .col-lg-5-5 {
        max-width: 45.83333333%;
        flex: 0 0 45.83333333%;
    }

    .col-lg-5-75 {
        max-width: 47.91666667%;
        flex: 0 0 47.91666667%;
    }

    .col-lg-6-25 {
        max-width: 52.08333333%;
        flex: 0 0 52.08333333%;
    }

    .col-lg-6-5 {
        max-width: 54.16666667%;
        flex: 0 0 54.16666667%;
    }

    .col-lg-7-5 {
        max-width: 62.5%;
        flex: 0 0 62.5%;
    }

    .col-lg-8-5 {
        max-width: 70.83333333%;
        flex: 0 0 70.83333333%;
    }

    .col-lg-9-5 {
        max-width: 79.166666667%;
        flex: 0 0 79.166666667%;
    }

    .col-lg-10-5 {
        max-width: 87.5%;
        flex: 0 0 87.5%;
    }

    .col-lg-10-75 {
        max-width: 89.58333333%;
        flex: 0 0 89.58333333%;
    }

    .col-lg-10-85 {
        max-width: 90.41666667%;
        flex: 0 0 90.41666667%;
    }

    .col-lg-11-4 {
        max-width: 95%;
        flex: 0 0 95%;
    }

    .col-lg-11-5 {
        max-width: 95.83333333%;
        flex: 0 0 95.83333333%;
    }
}

@media only screen and (max-width: 768px) {

    /*Changes due to bootstrap to Mui start*/
    .clMainSingleFESug {
        box-shadow: 0 0 5px #ccc;
    }

    .clMainCanOverAllRate {
        margin: 20px 0;
    }

    .MuiDialog-container .frmElement {
        padding: 0rem !important;
    }

    .clFEFeedback+.clDispFlex {
        display: block;
    }

    .marL70R0 {
        margin-left: 0px;
    }

    .clResMenuGrid2 {
        display: grid;
        grid-template-columns: auto auto;
    }

    .clMainLeftPart {
        /* max-height: 40%;
         height: 40%; */
        overflow: hidden;
    }

    .clMarResT15 {
        margin-top: 15px !important;
        /*Changes due to bootstrap to Mui start*/
    }

    .clAddQue {
        display: block;
        text-align: right;
    }

    #divTabMainLiveIntCanRes {
        overflow: hidden;
    }

    .clMainFESuggImgs {
        max-height: 200px;
        overflow-y: auto;
    }

    .clFESingleSug+.MuiCollapse-container {
        max-height: 200px !important;
        overflow-y: auto !important;
    }

    #divMainVideoIntLarge {
        width: 100%;
    }

    #spnCanName {
        font-size: 12px;
    }

    .btnContained {
        font-size: 16px !important;
    }

    #mediaAudoCall {
        /*  margin-left: 15px; */
        align-self: center;
    }

    #smallBtnBar {
        background-color: #ffffff;
        flex-grow: 1;
        /* margin-left: auto */
        justify-content: flex-end;
    }

    #smallBtnBar img {
        width: 32px;
        height: 32px;
    }

    .clLoadingFloTxt {
        top: 10px;
    }

    .resPadL0 {
        padding-left: 0;
    }

    .clCanDetMain {
        background-color: #ffffff;
        padding: 6px 8px;
    }

    .clCanInfo {
        padding-top: 0px;
        /* text-align: center; */
    }

    #divMainTabItems {
        padding-left: 0;
        padding-right: 0px;
        background-color: #ffffff;
        /* #f4f5ef */
    }

    .clResColorWhite {
        color: #333333 !important;
        /*Menu starts*/
    }

    .clMenu {
        position: fixed;
        bottom: 0px;
        width: 100%;
        z-index: 1000;
    }

    .clMenu ul {
        display: flex;
        justify-content: space-around;
        margin-bottom: 0px;
    }

    #divMainIntMenu {
        min-height: auto !important;
    }

    .clMenu ul li:first-child {
        margin-bottom: 0;
        padding-top: 10px;
    }

    .clMenu ul li {
        display: inline-block;
        width: auto;
        padding: 6px 10px;
        /*#liFloLogo{
         display: none
         }
         #liLiveInt, #liHistory{
         display: none;
         }
         #liFESugg, #liChat{
         display: block;
         } */
        /*Menu over*/
        /*Mobile Hamburger menu starts*/
    }

    .clMobileMainMenu {
        width: 250px;
        color: #ffffff;
    }

    .clMobileMainMenu ul,
    .clMobileMainMenu .flexDisplay {
        border-bottom: dashed 1px #ffffff;
    }

    .MuiDrawer-paper {
        background: #c4ab00 !important;
        padding: 0 12px;
    }

    .clMobileMenuLogo {
        text-align: center;
        display: inline-block;
    }

    .clMobileSubMenuTitle {
        font-weight: 700;
        padding-top: 15px;
    }

    #MobileMenuTitle {
        font-family: 'Raleway Bold', 'Raleway Regular', 'Raleway';
        font-weight: 700;
        font-style: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        margin-top: 10px;
    }

    #MobileMenuSubTitle {
        font-family: 'Raleway';
        font-weight: 400;
        font-style: normal;
        font-size: 11px;
    }

    .flexDisplay {
        display: flex;
        padding-bottom: 5px;
    }

    .clFlexGrow100 {
        flex-grow: 100%;
        width: 100%;
        text-align: center;
        margin-top: 15px;
    }

    .clMobileMenuClose .MuiSvgIcon-root {
        font-size: 2.5rem;
        color: #ffffff;
    }

    .clMobileMenuClose .MuiButtonBase-root {
        margin-left: -12px;
        /*Mobile Hamburger menu over*/
    }

    .clTabHed {
        display: none;
    }

    .clCESub {
        padding-top: 0px;
        /* .clTabPanel{
         .clMarT20R10.clTxtLeft{
         margin-top: 0;
         }
         } */
    }

    #divMainVideoIntSmallInside {
        max-width: 100%;
        position: relative;
        left: 20px;
        /* top: 5px; */
        width: 100% !important;
    }

    #divMainCanCallOnOff {
        float: right;
        padding-right: 15px;
    }

    #divRowRes2Media {
        position: relative;
        top: 42px;
    }

    .resPadR0 {
        padding-right: 0;
    }

    .resPadL0 {
        padding-left: 0;
    }

    .resH {
        display: none;
    }

    .clMainFBDialogSingleHed {
        margin-bottom: 10px;
    }

    .taCanFB {
        width: 90%;
        /*right side part starts*/
    }

    .clMainRightPart .clTabData {
        background-color: #ccc;
        padding: 10px;
    }

    .clMainRightPart #divJDContainer,
    .clMainRightPart #divCPContainer {
        background-color: #ffffff;
        padding: 8px;
    }

    .clResTabLabel {
        font-size: 12px;
        letter-spacing: 0.5px;
        /* padding-bottom: 10px; */
        font-weight: 700;
        font-family: 'Raleway Bold', 'Raleway Regular', 'Raleway';
        padding: 0 12px 0 8px;
    }

    .clTabDataInside {
        padding-left: 0;
    }

    .clMainRightPart {
        /* max-height: 40%;
         height: 30%; */
        overflow-y: auto;
        padding-top: 10px;
        background: #f5f5ef;
        margin-top: 15px;
        border-top-right-radius: 40px;
        border-top-left-radius: 40px;
        /* padding-left: 30px;
         padding-right: 25px; */
        padding-left: 15px;
        padding-right: 8px;
    }

    .clMainRightPart .MuiInputBase-root {
        margin: 0 !important;
    }

    .frmElement .MuiFormControl-fullWidth {
        width: 97% !important;
    }

    .clRightPartGray {
        padding-left: 0px;
        padding-right: 0px;
        border-bottom-left-radius: 0;
        border-top-right-radius: 15px;
        /* max-height: 300px; */
        /* left: 0; */
        /* padding-top: 25px; */
        /* background-color:$themeWhiteColor; */
    }

    .clRightPartGrayInner {
        margin: 0 0 20px 0;
        min-height: 100px;
        padding-right: 4px;
    }

    .clFEFeedback .frmElement {
        text-align: left !important;
        justify-content: inherit !important;
    }

    .clMainSingleFESug,
    .clMainSkillRate {
        padding-left: 10px;
        padding-right: 10px;
    }

    .clSeqGreen {
        padding-left: 0;
    }

    .resPadT10 {
        padding-top: 10px;
    }

    .clMarT20R10 {
        margin-top: 10px;
    }

    .clSkillRate {
        padding: 18px 20px;
    }

    .clMainImgSwitch {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .clMobMenuLIInside {
        padding-left: 30px !important;
    }

    .CodeMirror-gutters {
        z-index: auto;
        /*right side part starts*/
        /*bottom statusbar start*/
    }

    .clBotBarSec {
        border-right: 0;
    }

    #divMainBotBar {
        margin-left: 0;
    }

    #divSubBotBar {
        width: 100%;
        /* bottom:48px */
    }

    #divSubBotBar .clBotBarSec:nth-child(2) {
        text-align: right;
        /*bottom statusbar over*/
    }
}

@media only screen and (max-width: 567px) {
    .col-1-25 {
        max-width: 10.41666667%;
        flex: 0 0 10.41666667%;
    }

    .col-10-75 {
        max-width: 89.58333333%;
        flex: 0 0 89.58333333%;
    }

    .col-0-5 {
        max-width: 4.166666667%;
        flex: 0 0 4.166666667%;
    }

    .col-1-5 {
        max-width: 12.5%;
        flex: 0 0 12.5%;
    }

    .col-2-5 {
        max-width: 20.83333333%;
        flex: 0 0 20.83333333%;
    }

    .col-3-5 {
        max-width: 29.16666667%;
        flex: 0 0 29.16666667%;
    }

    .col-4-5 {
        max-width: 37.5%;
        flex: 0 0 37.5%;
    }

    .col-5-5 {
        max-width: 45.83333333%;
        flex: 0 0 45.83333333%;
    }

    .col-6-5 {
        max-width: 54.16666667%;
        flex: 0 0 54.16666667%;
    }

    .col-7-5 {
        max-width: 62.5%;
        flex: 0 0 62.5%;
    }

    .col-8-5 {
        max-width: 70.83333333%;
        flex: 0 0 70.83333333%;
    }

    .col-9-5 {
        max-width: 79.166666667%;
        flex: 0 0 79.166666667%;
    }

    .col-10-5 {
        max-width: 87.5%;
        flex: 0 0 87.5%;
    }

    .col-11-5 {
        max-width: 95.83333333%;
        flex: 0 0 95.83333333%;
    }

    .clTabHed {
        margin-bottom: 10px;
    }
}

/*Responsive design over*/
.clContactOther img {
    width: 32px;
    height: 32px;
}

.clContactOther .clSpnMenuItemTxt {
    font-size: 12px;
    color: #0033ff;
    padding-left: 12px;
    line-height: 18px;
    font-weight: normal;
    font-family: 'Raleway';
    font-weight: 400;
    font-style: normal;
}

.clContactOther .MuiPaper-root {
    border: none;
    border-radius: 6px;
    -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3490196078);
    /* -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.349019607843137); */
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3490196078);
    z-index: 100;
}

/* .clPointerEventNone
 {
 pointer-events:none;
 } */
#audioWaveForm {
    padding: 10px 20px;
}

.boldTxt {
    font-weight: 700;
    font-size: 24px;
}

.iconContainer {
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 75px;
    width: 75px;
    border-radius: 100%;
    animation: all 1s;
}

.iconContainer span {
    position: relative;
}

.iconContainer span img {
    animation: all 1s;
}

.iconContainer ::after {
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -8px;
    left: -8px;
    right: 0;
    bottom: 0;
    content: '';
    height: 100%;
    width: 100%;
    border: 4px solid rgba(0, 0, 0, 0.2);
    border-radius: 100%;
    animation-name: rippleEffect;
    animation-duration: 3s;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    z-index: 10;
}

.iconContainer ::before {
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -8px;
    left: -8px;
    right: 0;
    bottom: 0;
    content: '';
    height: 100%;
    width: 100%;
    border: 4px solid rgba(0, 0, 0, 0.2);
    border-radius: 100%;
    animation-name: rippleEffect;
    animation-duration: 3s;
    animation-delay: 0.5s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    z-index: 10;
}

@keyframes rippleEffect {
    from {
        opacity: 1;
        transform: scale3d(0.75, 0.75, 1);
    }

    to {
        opacity: 0;
        transform: scale3d(2.5, 2.5, 1);
    }
}

.clSpecialImage {
    position: relative;
    top: -2px;
    left: -2px;
}

.MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}

.MuiSvgIcon-colorPrimary {
    color: #3f51b5;
}

.MuiSvgIcon-colorSecondary {
    color: #f50057;
}

.MuiSvgIcon-colorAction {
    color: rgba(0, 0, 0, 0.54);
}

.MuiSvgIcon-colorError {
    color: #f44336;
}

.MuiSvgIcon-colorDisabled {
    color: rgba(0, 0, 0, 0.26);
}

.MuiSvgIcon-fontSizeInherit {
    font-size: inherit;
}

.MuiSvgIcon-fontSizeSmall {
    font-size: 1.25rem;
}

.MuiSvgIcon-fontSizeLarge {
    font-size: 2.1875rem;
}

::placeholder {
    opacity: 0.7;
    color: #bbb;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.marL70R0 {
    margin-left: 70px;
}

.wordBreak {
    word-break: break-word;
}

.clNoWrap {
    white-space: nowrap;
}

.clJustifyContentEvenly {
    justify-content: space-evenly;
}

.clPad15 {
    padding: 15px;
}

.clMainFESuggImgs {
    max-height: 500px;
    overflow-y: auto;
}

.ck-editor__editable {
    min-height: 250px;
    max-height: 250px;
    overflow-y: auto !important;
}

.clFESingleSug+.MuiCollapse-container {
    max-height: 350px !important;
    overflow-y: auto !important;
}

/* Initial loading start */
.clLoadingPer {
    font-size: 100px;
    color: #d0d0d0;
    position: relative;
    top: -50px;
    font-family: 'Raleway-Thin';
}

.clLoadingFloTxt {
    color: #747774;
    font-size: 24px;
    font-family: 'Raleway-Thin';
    position: relative;
    top: 40px;
}

/* Initial loading over */

/* Overall FB CK Editor start */
.ckeValidLabel {
    text-align: right;
    width: 100%;
    font-size: 0.75rem;
    color: #f44336;
}

/* Overall FB CK Editor over */

/* Menu starts */
.clUserMentor #liFloLogo {
    cursor: pointer;
}

#divMainIntMenu {
    min-height: 600px;
}

.clMenu {
    background: var(--themeGreenColor);
}

.clMenu ul {
    list-style: none;
    padding-left: 0;
    cursor: var(--cursorDefault);
    margin: 0;
}

.clMenu ul li.active {
    background-color: #fff;
}

.clCanInfo {
    line-height: 1.1;
    padding-top: 18px;
}

.clMenu ul li:first-child {
    margin-bottom: 38px;
    padding-top: 18px;
}

.clMenu ul li {
    padding: 14px 0;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    margin: 0px;
}

.clMenu ul li img {
    /*width: 36px;*/
    height: 36px;
}

.clMenuShadow {
    width: 100%;
    height: 10px;
    margin: 18px 0;
}

.clMenu ul li:first-child img {
    width: 30px;
    height: 30px;
}

.clIconBlackColor {
    filter: invert(0%) sepia(0%) saturate(7%) hue-rotate(322deg) brightness(103%) contrast(101%);
}

.clIconWhiteColor {
    filter: invert(100%) sepia(0%) saturate(7433%) hue-rotate(192deg) brightness(120%) contrast(114%);
}

.clIconGoldenColor {
    filter: invert(83%) sepia(43%) saturate(5108%) hue-rotate(12deg) brightness(91%) contrast(101%);
}

.clIconBlueColor {
    filter: invert(12%) sepia(97%) saturate(5733%) hue-rotate(233deg) brightness(97%) contrast(118%);
}

.padR0 {
    padding-right: 0;
}

.clMainItemIntPg {
    display: none;
}

/* #liFESugg, #liChat{
    display: none;
} */
/* Menu over */

.MuiInputLabel-outlined {
    transform: translate(12px, 12px) scale(1);
}

/* Variable Definition */
:root {
    --themeGreenColor: rgb(24, 24, 24);
}

/* Styles */
.clMenu {
    background: var(--themeGreenColor);
}

.clMenu ul li {
    color: #fff;
}

.clMenu ul li.active {
    color: var(--themeGreenColor);
}

.waitingPage {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.waitingPage p {
    font-size: 22px;
    text-align: center;
    margin-bottom: 0;
}

.waitingPage ul {
    font-size: 16px;
}

.waitingPage dyte-button {
    margin-top: 10px;
    align-self: center;
}

.dyteContainer {
    background: #f5f5ef;
    border-radius: 10px;
    padding: 15px 0;
}

.dyteContainer .dyteRecTime {
    display: flex;
    justify-content: space-between;
}

.MuiGrid-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.MuiGrid-item {
    margin: 0;
    box-sizing: border-box;
}

.MuiGrid-zeroMinWidth {
    min-width: 0;
}

.MuiGrid-direction-xs-column {
    flex-direction: column;
}

.MuiGrid-direction-xs-column-reverse {
    flex-direction: column-reverse;
}

.MuiGrid-direction-xs-row-reverse {
    flex-direction: row-reverse;
}

.MuiGrid-wrap-xs-nowrap {
    flex-wrap: nowrap;
}

.MuiGrid-wrap-xs-wrap-reverse {
    flex-wrap: wrap-reverse;
}

.MuiGrid-align-items-xs-center {
    align-items: center;
}

.MuiGrid-align-items-xs-flex-start {
    align-items: flex-start;
}

.MuiGrid-align-items-xs-flex-end {
    align-items: flex-end;
}

.MuiGrid-align-items-xs-baseline {
    align-items: baseline;
}

.MuiGrid-align-content-xs-center {
    align-content: center;
}

.MuiGrid-align-content-xs-flex-start {
    align-content: flex-start;
}

.MuiGrid-align-content-xs-flex-end {
    align-content: flex-end;
}

.MuiGrid-align-content-xs-space-between {
    align-content: space-between;
}

.MuiGrid-align-content-xs-space-around {
    align-content: space-around;
}

.MuiGrid-justify-content-xs-center {
    justify-content: center;
}

.MuiGrid-justify-content-xs-flex-end {
    justify-content: flex-end;
}

.MuiGrid-justify-content-xs-space-between {
    justify-content: space-between;
}

.MuiGrid-justify-content-xs-space-around {
    justify-content: space-around;
}

.MuiGrid-justify-content-xs-space-evenly {
    justify-content: space-evenly;
}

.MuiGrid-spacing-xs-1 {
    width: calc(100% + 8px);
    margin: -4px;
}

.MuiGrid-spacing-xs-1>.MuiGrid-item {
    padding: 4px;
}

.MuiGrid-spacing-xs-2 {
    width: calc(100% + 16px);
    margin: -8px;
}

.MuiGrid-spacing-xs-2>.MuiGrid-item {
    padding: 8px;
}

.MuiGrid-spacing-xs-3 {
    width: calc(100% + 24px);
    margin: -12px;
}

.MuiGrid-spacing-xs-3>.MuiGrid-item {
    padding: 12px;
}

.MuiGrid-spacing-xs-4 {
    width: calc(100% + 32px);
    margin: -16px;
}

.MuiGrid-spacing-xs-4>.MuiGrid-item {
    padding: 16px;
}

.MuiGrid-spacing-xs-5 {
    width: calc(100% + 40px);
    margin: -20px;
}

.MuiGrid-spacing-xs-5>.MuiGrid-item {
    padding: 20px;
}

.MuiGrid-spacing-xs-6 {
    width: calc(100% + 48px);
    margin: -24px;
}

.MuiGrid-spacing-xs-6>.MuiGrid-item {
    padding: 24px;
}

.MuiGrid-spacing-xs-7 {
    width: calc(100% + 56px);
    margin: -28px;
}

.MuiGrid-spacing-xs-7>.MuiGrid-item {
    padding: 28px;
}

.MuiGrid-spacing-xs-8 {
    width: calc(100% + 64px);
    margin: -32px;
}

.MuiGrid-spacing-xs-8>.MuiGrid-item {
    padding: 32px;
}

.MuiGrid-spacing-xs-9 {
    width: calc(100% + 72px);
    margin: -36px;
}

.MuiGrid-spacing-xs-9>.MuiGrid-item {
    padding: 36px;
}

.MuiGrid-spacing-xs-10 {
    width: calc(100% + 80px);
    margin: -40px;
}

.MuiGrid-spacing-xs-10>.MuiGrid-item {
    padding: 40px;
}

.MuiGrid-grid-xs-auto {
    flex-grow: 0;
    max-width: none;
    flex-basis: auto;
}

.MuiGrid-grid-xs-true {
    flex-grow: 1;
    max-width: 100%;
    flex-basis: 0;
}

.MuiGrid-grid-xs-1 {
    flex-grow: 0;
    max-width: 8.333333%;
    flex-basis: 8.333333%;
}

.MuiGrid-grid-xs-2 {
    flex-grow: 0;
    max-width: 16.666667%;
    flex-basis: 16.666667%;
}

.MuiGrid-grid-xs-3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
}

.MuiGrid-grid-xs-4 {
    flex-grow: 0;
    max-width: 33.333333%;
    flex-basis: 33.333333%;
}

.MuiGrid-grid-xs-5 {
    flex-grow: 0;
    max-width: 41.666667%;
    flex-basis: 41.666667%;
}

.MuiGrid-grid-xs-6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
}

.MuiGrid-grid-xs-7 {
    flex-grow: 0;
    max-width: 58.333333%;
    flex-basis: 58.333333%;
}

.MuiGrid-grid-xs-8 {
    flex-grow: 0;
    max-width: 66.666667%;
    flex-basis: 66.666667%;
}

.MuiGrid-grid-xs-9 {
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%;
}

.MuiGrid-grid-xs-10 {
    flex-grow: 0;
    max-width: 83.333333%;
    flex-basis: 83.333333%;
}

.MuiGrid-grid-xs-11 {
    flex-grow: 0;
    max-width: 91.666667%;
    flex-basis: 91.666667%;
}

.MuiGrid-grid-xs-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
}

@media (min-width: 600px) {
    .MuiGrid-grid-sm-auto {
        flex-grow: 0;
        max-width: none;
        flex-basis: auto;
    }

    .MuiGrid-grid-sm-true {
        flex-grow: 1;
        max-width: 100%;
        flex-basis: 0;
    }

    .MuiGrid-grid-sm-1 {
        flex-grow: 0;
        max-width: 8.333333%;
        flex-basis: 8.333333%;
    }

    .MuiGrid-grid-sm-2 {
        flex-grow: 0;
        max-width: 16.666667%;
        flex-basis: 16.666667%;
    }

    .MuiGrid-grid-sm-3 {
        flex-grow: 0;
        max-width: 25%;
        flex-basis: 25%;
    }

    .MuiGrid-grid-sm-4 {
        flex-grow: 0;
        max-width: 33.333333%;
        flex-basis: 33.333333%;
    }

    .MuiGrid-grid-sm-5 {
        flex-grow: 0;
        max-width: 41.666667%;
        flex-basis: 41.666667%;
    }

    .MuiGrid-grid-sm-6 {
        flex-grow: 0;
        max-width: 50%;
        flex-basis: 50%;
    }

    .MuiGrid-grid-sm-7 {
        flex-grow: 0;
        max-width: 58.333333%;
        flex-basis: 58.333333%;
    }

    .MuiGrid-grid-sm-8 {
        flex-grow: 0;
        max-width: 66.666667%;
        flex-basis: 66.666667%;
    }

    .MuiGrid-grid-sm-9 {
        flex-grow: 0;
        max-width: 75%;
        flex-basis: 75%;
    }

    .MuiGrid-grid-sm-10 {
        flex-grow: 0;
        max-width: 83.333333%;
        flex-basis: 83.333333%;
    }

    .MuiGrid-grid-sm-11 {
        flex-grow: 0;
        max-width: 91.666667%;
        flex-basis: 91.666667%;
    }

    .MuiGrid-grid-sm-12 {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
    }
}

@media (min-width: 960px) {
    .MuiGrid-grid-md-auto {
        flex-grow: 0;
        max-width: none;
        flex-basis: auto;
    }

    .MuiGrid-grid-md-true {
        flex-grow: 1;
        max-width: 100%;
        flex-basis: 0;
    }

    .MuiGrid-grid-md-1 {
        flex-grow: 0;
        max-width: 8.333333%;
        flex-basis: 8.333333%;
    }

    .MuiGrid-grid-md-2 {
        flex-grow: 0;
        max-width: 16.666667%;
        flex-basis: 16.666667%;
    }

    .MuiGrid-grid-md-3 {
        flex-grow: 0;
        max-width: 25%;
        flex-basis: 25%;
    }

    .MuiGrid-grid-md-4 {
        flex-grow: 0;
        max-width: 33.333333%;
        flex-basis: 33.333333%;
    }

    .MuiGrid-grid-md-5 {
        flex-grow: 0;
        max-width: 41.666667%;
        flex-basis: 41.666667%;
    }

    .MuiGrid-grid-md-6 {
        flex-grow: 0;
        max-width: 50%;
        flex-basis: 50%;
    }

    .MuiGrid-grid-md-7 {
        flex-grow: 0;
        max-width: 58.333333%;
        flex-basis: 58.333333%;
    }

    .MuiGrid-grid-md-8 {
        flex-grow: 0;
        max-width: 66.666667%;
        flex-basis: 66.666667%;
    }

    .MuiGrid-grid-md-9 {
        flex-grow: 0;
        max-width: 75%;
        flex-basis: 75%;
    }

    .MuiGrid-grid-md-10 {
        flex-grow: 0;
        max-width: 83.333333%;
        flex-basis: 83.333333%;
    }

    .MuiGrid-grid-md-11 {
        flex-grow: 0;
        max-width: 91.666667%;
        flex-basis: 91.666667%;
    }

    .MuiGrid-grid-md-12 {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
    }
}

@media (min-width: 1280px) {
    .MuiGrid-grid-lg-auto {
        flex-grow: 0;
        max-width: none;
        flex-basis: auto;
    }

    .MuiGrid-grid-lg-true {
        flex-grow: 1;
        max-width: 100%;
        flex-basis: 0;
    }

    .MuiGrid-grid-lg-1 {
        flex-grow: 0;
        max-width: 8.333333%;
        flex-basis: 8.333333%;
    }

    .MuiGrid-grid-lg-2 {
        flex-grow: 0;
        max-width: 16.666667%;
        flex-basis: 16.666667%;
    }

    .MuiGrid-grid-lg-3 {
        flex-grow: 0;
        max-width: 25%;
        flex-basis: 25%;
    }

    .MuiGrid-grid-lg-4 {
        flex-grow: 0;
        max-width: 33.333333%;
        flex-basis: 33.333333%;
    }

    .MuiGrid-grid-lg-5 {
        flex-grow: 0;
        max-width: 41.666667%;
        flex-basis: 41.666667%;
    }

    .MuiGrid-grid-lg-6 {
        flex-grow: 0;
        max-width: 50%;
        flex-basis: 50%;
    }

    .MuiGrid-grid-lg-7 {
        flex-grow: 0;
        max-width: 58.333333%;
        flex-basis: 58.333333%;
    }

    .MuiGrid-grid-lg-8 {
        flex-grow: 0;
        max-width: 66.666667%;
        flex-basis: 66.666667%;
    }

    .MuiGrid-grid-lg-9 {
        flex-grow: 0;
        max-width: 75%;
        flex-basis: 75%;
    }

    .MuiGrid-grid-lg-10 {
        flex-grow: 0;
        max-width: 83.333333%;
        flex-basis: 83.333333%;
    }

    .MuiGrid-grid-lg-11 {
        flex-grow: 0;
        max-width: 91.666667%;
        flex-basis: 91.666667%;
    }

    .MuiGrid-grid-lg-12 {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
    }
}

@media (min-width: 1920px) {
    .MuiGrid-grid-xl-auto {
        flex-grow: 0;
        max-width: none;
        flex-basis: auto;
    }

    .MuiGrid-grid-xl-true {
        flex-grow: 1;
        max-width: 100%;
        flex-basis: 0;
    }

    .MuiGrid-grid-xl-1 {
        flex-grow: 0;
        max-width: 8.333333%;
        flex-basis: 8.333333%;
    }

    .MuiGrid-grid-xl-2 {
        flex-grow: 0;
        max-width: 16.666667%;
        flex-basis: 16.666667%;
    }

    .MuiGrid-grid-xl-3 {
        flex-grow: 0;
        max-width: 25%;
        flex-basis: 25%;
    }

    .MuiGrid-grid-xl-4 {
        flex-grow: 0;
        max-width: 33.333333%;
        flex-basis: 33.333333%;
    }

    .MuiGrid-grid-xl-5 {
        flex-grow: 0;
        max-width: 41.666667%;
        flex-basis: 41.666667%;
    }

    .MuiGrid-grid-xl-6 {
        flex-grow: 0;
        max-width: 50%;
        flex-basis: 50%;
    }

    .MuiGrid-grid-xl-7 {
        flex-grow: 0;
        max-width: 58.333333%;
        flex-basis: 58.333333%;
    }

    .MuiGrid-grid-xl-8 {
        flex-grow: 0;
        max-width: 66.666667%;
        flex-basis: 66.666667%;
    }

    .MuiGrid-grid-xl-9 {
        flex-grow: 0;
        max-width: 75%;
        flex-basis: 75%;
    }

    .MuiGrid-grid-xl-10 {
        flex-grow: 0;
        max-width: 83.333333%;
        flex-basis: 83.333333%;
    }

    .MuiGrid-grid-xl-11 {
        flex-grow: 0;
        max-width: 91.666667%;
        flex-basis: 91.666667%;
    }

    .MuiGrid-grid-xl-12 {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
    }
}

.jss8 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.jss9 {
    color: #ffffff;
    border: 1px solid #0033FF;
    padding: 4px 30px;
    font-size: 14px;
    font-style: normal;
    font-family: Raleway, sans-serif;
    font-weight: 400;
    border-radius: 25px;
    text-transform: inherit;
    background-color: #0033FF;
}

.jss9:hover {
    color: #ffffff;
    box-shadow: 0 0 10px #ccc;
    background-color: #0033FF;
}

.jss10 {
    right: 0;
    position: absolute;
}

.jss11 {
    width: 50px;
    max-width: 50px;
}

@media (max-width: 959.95px) {
    .jss11 {
        width: 30px;
        height: 30px;
        margin-top: 6px;
    }
}

MuiButton-root {
    color: rgba(0, 0, 0, 0.87);
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
}

.MuiButton-root:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
}

.MuiButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
}

@media (hover: none) {
    .MuiButton-root:hover {
        background-color: transparent;
    }
}

.MuiButton-root:hover.Mui-disabled {
    background-color: transparent;
}

.MuiButton-label {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
}

.MuiButton-text {
    padding: 6px 8px;
}

.MuiButton-textPrimary {
    color: #3f51b5;
}

.MuiButton-textPrimary:hover {
    background-color: rgba(63, 81, 181, 0.04);
}

@media (hover: none) {
    .MuiButton-textPrimary:hover {
        background-color: transparent;
    }
}

.MuiButton-textSecondary {
    color: #f50057;
}

.MuiButton-textSecondary:hover {
    background-color: rgba(245, 0, 87, 0.04);
}

@media (hover: none) {
    .MuiButton-textSecondary:hover {
        background-color: transparent;
    }
}

.MuiButton-outlined {
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 5px 15px;
}

.MuiButton-outlined.Mui-disabled {
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.MuiButton-outlinedPrimary {
    color: #3f51b5;
    border: 1px solid rgba(63, 81, 181, 0.5);
}

.MuiButton-outlinedPrimary:hover {
    border: 1px solid #3f51b5;
    background-color: rgba(63, 81, 181, 0.04);
}

@media (hover: none) {
    .MuiButton-outlinedPrimary:hover {
        background-color: transparent;
    }
}

.MuiButton-outlinedSecondary {
    color: #f50057;
    border: 1px solid rgba(245, 0, 87, 0.5);
}

.MuiButton-outlinedSecondary:hover {
    border: 1px solid #f50057;
    background-color: rgba(245, 0, 87, 0.04);
}

.MuiButton-outlinedSecondary.Mui-disabled {
    border: 1px solid rgba(0, 0, 0, 0.26);
}

@media (hover: none) {
    .MuiButton-outlinedSecondary:hover {
        background-color: transparent;
    }
}

.MuiButton-contained {
    color: rgba(0, 0, 0, 0.87);
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    background-color: #e0e0e0;
}

.MuiButton-contained:hover {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    background-color: #d5d5d5;
}

.MuiButton-contained.Mui-focusVisible {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.MuiButton-contained:active {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.MuiButton-contained.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
}

@media (hover: none) {
    .MuiButton-contained:hover {
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        background-color: #e0e0e0;
    }
}

.MuiButton-contained:hover.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12);
}

.MuiButton-containedPrimary {
    color: #fff;
    background-color: #3f51b5;
}

.MuiButton-containedPrimary:hover {
    background-color: #303f9f;
}

@media (hover: none) {
    .MuiButton-containedPrimary:hover {
        background-color: #3f51b5;
    }
}

.MuiButton-containedSecondary {
    color: #fff;
    background-color: #f50057;
}

.MuiButton-containedSecondary:hover {
    background-color: #c51162;
}

@media (hover: none) {
    .MuiButton-containedSecondary:hover {
        background-color: #f50057;
    }
}

.MuiButton-disableElevation {
    box-shadow: none;
}

.MuiButton-disableElevation:hover {
    box-shadow: none;
}

.MuiButton-disableElevation.Mui-focusVisible {
    box-shadow: none;
}

.MuiButton-disableElevation:active {
    box-shadow: none;
}

.MuiButton-disableElevation.Mui-disabled {
    box-shadow: none;
}

.MuiButton-colorInherit {
    color: inherit;
    border-color: currentColor;
}

.MuiButton-textSizeSmall {
    padding: 4px 5px;
    font-size: 0.8125rem;
}

.MuiButton-textSizeLarge {
    padding: 8px 11px;
    font-size: 0.9375rem;
}

.MuiButton-outlinedSizeSmall {
    padding: 3px 9px;
    font-size: 0.8125rem;
}

.MuiButton-outlinedSizeLarge {
    padding: 7px 21px;
    font-size: 0.9375rem;
}

.MuiButton-containedSizeSmall {
    padding: 4px 10px;
    font-size: 0.8125rem;
}

.MuiButton-containedSizeLarge {
    padding: 8px 22px;
    font-size: 0.9375rem;
}

.MuiButton-fullWidth {
    width: 100%;
}

.MuiButton-startIcon {
    display: inherit;
    margin-left: -4px;
    margin-right: 8px;
}

.MuiButton-startIcon.MuiButton-iconSizeSmall {
    margin-left: -2px;
}

.MuiButton-endIcon {
    display: inherit;
    margin-left: 8px;
    margin-right: -4px;
}

.MuiButton-endIcon.MuiButton-iconSizeSmall {
    margin-right: -2px;
}

.MuiButton-iconSizeSmall>*:first-child {
    font-size: 18px;
}

.MuiButton-iconSizeMedium>*:first-child {
    font-size: 20px;
}

.MuiButton-iconSizeLarge>*:first-child {
    font-size: 22px;
}

.MuiButton-root {
    color: rgba(0, 0, 0, 0.87);
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
}

.MuiButton-root:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
}

.MuiButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
}

@media (hover: none) {
    .MuiButton-root:hover {
        background-color: transparent;
    }
}

.MuiButton-root:hover.Mui-disabled {
    background-color: transparent;
}

.MuiButton-label {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
}

.MuiButton-text {
    padding: 6px 8px;
}

.MuiButton-textPrimary {
    color: #3f51b5;
}

.MuiButton-textPrimary:hover {
    background-color: rgba(63, 81, 181, 0.04);
}

@media (hover: none) {
    .MuiButton-textPrimary:hover {
        background-color: transparent;
    }
}

.MuiButton-textSecondary {
    color: #f50057;
}

.MuiButton-textSecondary:hover {
    background-color: rgba(245, 0, 87, 0.04);
}

@media (hover: none) {
    .MuiButton-textSecondary:hover {
        background-color: transparent;
    }
}

.MuiButton-outlined {
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 5px 15px;
}

.MuiButton-outlined.Mui-disabled {
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.MuiButton-outlinedPrimary {
    color: #3f51b5;
    border: 1px solid rgba(63, 81, 181, 0.5);
}

.MuiButton-outlinedPrimary:hover {
    border: 1px solid #3f51b5;
    background-color: rgba(63, 81, 181, 0.04);
}

@media (hover: none) {
    .MuiButton-outlinedPrimary:hover {
        background-color: transparent;
    }
}

.MuiButton-outlinedSecondary {
    color: #f50057;
    border: 1px solid rgba(245, 0, 87, 0.5);
}

.MuiButton-outlinedSecondary:hover {
    border: 1px solid #f50057;
    background-color: rgba(245, 0, 87, 0.04);
}

.MuiButton-outlinedSecondary.Mui-disabled {
    border: 1px solid rgba(0, 0, 0, 0.26);
}

@media (hover: none) {
    .MuiButton-outlinedSecondary:hover {
        background-color: transparent;
    }
}

.MuiButton-contained {
    color: rgba(0, 0, 0, 0.87);
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    background-color: #e0e0e0;
}

.MuiButton-contained:hover {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    background-color: #d5d5d5;
}

.MuiButton-contained.Mui-focusVisible {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.MuiButton-contained:active {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.MuiButton-contained.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
}

@media (hover: none) {
    .MuiButton-contained:hover {
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        background-color: #e0e0e0;
    }
}

.MuiButton-contained:hover.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12);
}

.MuiButton-containedPrimary {
    color: #fff;
    background-color: #3f51b5;
}

.MuiButton-containedPrimary:hover {
    background-color: #303f9f;
}

@media (hover: none) {
    .MuiButton-containedPrimary:hover {
        background-color: #3f51b5;
    }
}

.MuiButton-containedSecondary {
    color: #fff;
    background-color: #f50057;
}

.MuiButton-containedSecondary:hover {
    background-color: #c51162;
}

@media (hover: none) {
    .MuiButton-containedSecondary:hover {
        background-color: #f50057;
    }
}

.MuiButton-disableElevation {
    box-shadow: none;
}

.MuiButton-disableElevation:hover {
    box-shadow: none;
}

.MuiButton-disableElevation.Mui-focusVisible {
    box-shadow: none;
}

.MuiButton-disableElevation:active {
    box-shadow: none;
}

.MuiButton-disableElevation.Mui-disabled {
    box-shadow: none;
}

.MuiButton-colorInherit {
    color: inherit;
    border-color: currentColor;
}

.MuiButton-textSizeSmall {
    padding: 4px 5px;
    font-size: 0.8125rem;
}

.MuiButton-textSizeLarge {
    padding: 8px 11px;
    font-size: 0.9375rem;
}

.MuiButton-outlinedSizeSmall {
    padding: 3px 9px;
    font-size: 0.8125rem;
}

.MuiButton-outlinedSizeLarge {
    padding: 7px 21px;
    font-size: 0.9375rem;
}

.MuiButton-containedSizeSmall {
    padding: 4px 10px;
    font-size: 0.8125rem;
}

.MuiButton-containedSizeLarge {
    padding: 8px 22px;
    font-size: 0.9375rem;
}

.MuiButton-fullWidth {
    width: 100%;
}

.MuiButton-startIcon {
    display: inherit;
    margin-left: -4px;
    margin-right: 8px;
}

.MuiButton-startIcon.MuiButton-iconSizeSmall {
    margin-left: -2px;
}

.MuiButton-endIcon {
    display: inherit;
    margin-left: 8px;
    margin-right: -4px;
}

.MuiButton-endIcon.MuiButton-iconSizeSmall {
    margin-right: -2px;
}

.MuiButton-iconSizeSmall>*:first-child {
    font-size: 18px;
}

.MuiButton-iconSizeMedium>*:first-child {
    font-size: 20px;
}

.MuiButton-iconSizeLarge>*:first-child {
    font-size: 22px;
}


.jss3 {
    color: #000000;
    font-size: 14px;
    font-style: normal;
    font-family: Raleway Bold, Raleway Regular, Raleway, sans-serif;
    font-weight: 700;
}

.jss4 {
    top: 6px;
    color: rgba(2, 167, 240, 0.98);
    right: 8px;
    position: absolute;
}

.jss5 {
    color: #0033ff;
    border: 1px solid #0033ff;
    padding: 4px 30px;
    font-size: 14px;
    font-style: normal;
    font-family: Raleway, sans-serif;
    font-weight: 400;
    border-radius: 25px;
    text-transform: inherit;
}

.jss5:hover {
    color: #0033ff !important;
    box-shadow: 0 0 10px #ccc;
    background-color: #ffffff !important;
}

.jss6 {
    color: #ffffff;
    border: 1px solid #0033ff;
    padding: 4px 30px;
    font-size: 14px;
    font-style: normal;
    font-family: Raleway, sans-serif;
    font-weight: 400;
    border-radius: 25px;
    text-transform: inherit;
    background-color: #0033FF;
}

.jss6:hover {
    color: #ffffff;
    background-color: #0033ff;
}

.error-404 {
    font-size: 1.5rem;
    margin-bottom: 50px;
    font-family: "Roboto", Sans-serif;
    font-weight: 400;
}

.elementor-heading-title {
    color: #040616;
    font-family: "Roboto", Sans-serif;
    font-size: 150px;
    font-weight: 600;
    line-height: 150px;
    letter-spacing: -3.53px;
}

.elementor-widget-container{
    text-align: center;
    color: #596472;
    font-family: "Roboto", Sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 28px;
    max-width: 50%;
}